import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NewCmpComponent } from './new-cmp/new-cmp.component';
import { SiguenosComponent } from './siguenos/siguenos.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { CambiosComponent } from './cambios/cambios.component';
import { InstalacionesComponent } from './instalaciones/instalaciones.component';
import { UbicacionComponent } from './ubicacion/ubicacion.component';
import { ContactoComponent } from './contacto/contacto.component';
import { TestimoniosComponent } from './testimonios/testimonios.component';

@NgModule({
  declarations: [
    AppComponent,
    NewCmpComponent,
    SiguenosComponent,
    ServiciosComponent,
    CambiosComponent,
    InstalacionesComponent,
    UbicacionComponent,
    ContactoComponent,
    TestimoniosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
