import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-cmp',
  templateUrl: './new-cmp.component.html',
  styleUrls: ['./new-cmp.component.css']
})
export class NewCmpComponent implements OnInit {
  texto = "En Spa Ever Light nos enfocamos en obtener las metas de nuestros pacientes usando los mejores métodos posibles. Con una historia de más de 20 años y cientos de clientes satisfechos, nos enorgullece poder mencionar que tenemos una solución para ti.";
  texto2 ="Spa Ever Light una empresa mexicana fundada en 1998 con el propósito de mejorar la salud, bienestar e imagen de nuestros pacientes. Nuestro concepto innovador en spas incorpora los tratamientos médicos de control de peso, con los tratamientos corporales y cosmetológicos más efectivos del mercado. En Spa Ever Light creemos que con una mentalidad correcta y determinada se pueden alcanzar las más altas expectativas. Con nuestro apoyo te mantendremos enfocado en conseguir la figura que deseas.";
  texto3 = "Nos enfocamos principalmente en tres áreas:";
  constructor() { }

  ngOnInit() {
  }

}
