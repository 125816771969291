import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalaciones',
  templateUrl: './instalaciones.component.html',
  styleUrls: ['./instalaciones.component.css']
})
export class InstalacionesComponent implements OnInit {
  currentJustify = 'center';
  constructor() { }

  ngOnInit() {
  }

}
